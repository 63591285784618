/* eslint-disable no-undef */
import React, { useRef, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Autocomplete,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import download from "downloadjs"


const center = { lat: 48.137154, lng: 11.576124 }; // munich

const Main = () => {
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const mode = "TRANSIT";

  console.log(duration);

  const originRef = useRef();
  const destinationRef = useRef();
  const datetimeRef = useRef();
  
  function getMarkers() {
    if (!directions) {
      return null;
    }
    const startLocation = directions.routes[0].legs[0].start_location;
    const endLocation = directions.routes[0].legs[0].end_location;
    return {
      start: { lat: startLocation.lat(), lng: startLocation.lng() },
      end: { lat: endLocation.lat(), lng: endLocation.lng() },
    };
  }

  async function generateGpx() {
    // get locations of markers
    let { start, end } = getMarkers();
    start = start.lat + "," + start.lng
    end = end.lat + "," + end.lng
    fetch("/api/convert?start=" + start + "&end=" + end + "&time=" + datetimeRef.current.value)
    .then(function(resp) {
      return resp.blob();
    }).then(function(blob) {
      download(blob);
    });
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }
    const directionsService = new google.maps.DirectionsService();
    await directionsService
      .route({
        origin: originRef.current.value,
        destination: destinationRef.current.value,
        travelMode: google.maps.TravelMode[mode],
        transitOptions: {
          departureTime: new Date(datetimeRef.current.value),
        },
      })
      .then((results) => {
        setDirections(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      })
      // eslint-disable-next-line no-restricted-globals
      .catch((e) => window.alert("Error Encountered \n" + e));
  }

  function clearRoute() {
    setDirections("");
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_WEBSITE_NAME,
    libraries: ["places"],
  });

  if (!isLoaded) {
    return <p>Loading in Progress </p>;
  }

  return (
    <>
      <div className="bg-blue-50 flex flex-col justify-between" style={{height: '100%'}}>
        <div className="flex flex-col w-full text-center">
          <h1 className="text-2xl font-semibold m-5">
            Google Maps Transit to GPX
          </h1>

        </div>

        <div className="flex" style={{ height: "100%" }}>

        <div className=" w-1/2 ">
            <div className="w-full h-full p-5">
              <GoogleMap
                center={center}
                zoom={5}
                mapContainerStyle={{ height: "100%" }}
                options={{
                  streetViewControl: false,
                  zoomControl: true,
                  fullscreenControl: false,
                  mapTypeControl: true,
                }}
              >
                <Marker position={center} />
                {directions && <DirectionsRenderer directions={directions} />}
              </GoogleMap>
            </div>
          </div>

          <div className="flex flex-col w-1/2">
            <div className="w-full">
              <div className="flex flex-col m-7">
                <label className="text-sm text-gray-900"> Origin </label>
                <Autocomplete>
                  <input
                    className=" p-2 w-full rounded-lg border border-gray-200"
                    placeholder="Enter Origin"
                    ref={originRef}
                  />
                </Autocomplete>
              </div>

              <div className="flex flex-col m-7">
                <label className="text-sm text-gray-900"> Destination </label>
                <Autocomplete>
                  <input
                    className="p-2 w-full rounded-lg border border-gray-200"
                    placeholder="Enter Destination"
                    ref={destinationRef}
                  />
                </Autocomplete>
              </div>

              <div className="flex flex-col m-7">
                <label className="text-sm text-gray-900"> Time </label>
                <input
                  className="p-2 w-full rounded-lg border border-gray-200"
                  placeholder="Enter Time"
                  type="datetime-local"
                  defaultValue={new Date().toISOString().slice(0, 16)}
                  ref={datetimeRef}
                />
              </div>

              <div className="flex justify-start items-cente">
                <div className="flex justify-around w-full">
                  <button
                    className="bg-blue-800 text-white py-1 px-5 rounded-full"
                    onClick={calculateRoute}
                  >
                    Calculate
                  </button>
                  {directions && (
                    <button
                      className="bg-blue-800 text-white py-1 px-5 rounded-full"
                      onClick={clearRoute}
                    >
                      Clear Path
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center ">
              <div className="m-2 w-3/4">
                <div className="bg-white rounded m-5">
                  <div className="flex justify-around">
                    <h3 className=" font-bold text-2xl p-3">Distance</h3>
                    <h3 className="text-blue-500 font-bold text-3xl p-3">
                      {directions ? distance : "0 km"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-start items-cente">
              <div className="flex justify-around w-full">
                <button
                  className={`py-1 px-5 rounded-full ${
                    directions ? "bg-blue-800 text-white" : "bg-gray-400 text-gray-700"
                  }`}
                  onClick={generateGpx}
                  disabled={!directions}
                >
                  Generate GPX
                </button>
              </div>
            </div>
            <div style={{flexGrow: "1"}}>
            </div>

            <div style={{margin: "3vh", display: "flex", flexWrap: "wrap"}}>
              <a href="https://www.buymeacoffee.com/alexpera" target="_blank" style={{flex: "auto"}} className="mt-3">
                <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" id="buycoffee" ></img>
              </a>
              {/* <p className="m-5" style={{textAlign: "right"}}>By <a href="https://github.com/AlexPerathoner">AlexPera</a></p> */}
              {/* add this as a button with a github logo */}
              <a href="https://github.com/AlexPerathoner" target="_blank" className="ml-5 mt-5">
                <img src="https://img.icons8.com/ios-glyphs/30/000000/github.png"/> By AlexPerathoner
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
